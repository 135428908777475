<template>
  <v-form>
    <v-alert
      v-if="alert"
      border="left"
      colored-border
      color="success"
      elevation="2"
      class="alert-edit"
      type="success"
      transition="fade-transition">
      Business successfully added! Re-routing...
    </v-alert>
    <v-overlay :value="overlay">
     <v-progress-circular
       indeterminate
       size="64"
     ></v-progress-circular>
   </v-overlay>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="business.name"
          outlined
          label="Business name"
          prepend-icon="mdi-domain"
          hide-details="auto"
          :error="business.name == '' && showError"
          :rules="[v => !!v || 'Field is required']"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
      <v-text-field
        v-model="business.owner"
        outlined
        label="Owner name"
        prepend-icon="mdi-account"
         hide-details="auto"
      ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="business.owner_contact_number"
          maxlength="15"
          outlined
          label="Telephone"
          prepend-icon="mdi-phone"
          hide-details="auto"
          @keypress.native="isNumber"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
      <v-text-field
        v-model="business.co_owner"
        outlined
        label="Co-owner name"
        prepend-icon="mdi-account"
        hide-details="auto"
      ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="business.co_owner_contact_number"
          maxlength="15"
          outlined
          label="Co-owner telephone"
          prepend-icon="mdi-phone"
          hide-details="auto"
          @keypress.native="isNumber"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
       <v-select
         v-model="selectedIndustry"
         :items="industryItems"
         item-value="id"
         item-text="name"
         label="Select industry"
         outlined
         prepend-icon="mdi-factory"
         hide-details="auto"
       >
      </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="business.type"
          :items="businessTypes"
          item-value="id"
          item-text="name"
          no-data-text="Please select industry"
          label="Select business type"
          outlined
          prepend-icon="mdi-factory"
          hide-details="auto"
        >
        </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="business.email"
          outlined
          label="Email address"
          prepend-icon="mdi-email"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        v-if="this.$store.getters.user.is_superuser"
      >
        <v-autocomplete
         v-model="staffSelected"
         :items="staffItems"
         :loading="isStaffLoading"
         :search-input.sync="staffSearch"
         hide-no-data
         hide-details="auto"
         item-text="name"
         item-value="id"
         label="Select staff"
         placeholder="Start typing to Search"
         prepend-icon="mdi-face-agent"
         return-object
         @change="selectStaff"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
      >
        <div class="ml-4 text-subtitle-2">Address</div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="business.street"
          outlined
          label="Street address"
          prepend-icon="mdi-map-marker"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="business.city"
          outlined
          label="City"
          prepend-icon="mdi-map-marker"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="business.county"
          outlined
          label="County"
          prepend-icon="mdi-map-marker"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="business.state"
          :items="usStates"
          item-text="name"
          item-value="name"
          label="State"
          outlined
          prepend-icon="mdi-map-marker"
          hide-details="auto"
        >
        </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="business.country"
          outlined
          label="Country"
          prepend-icon="mdi-map-marker"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="business.zip_code"
          outlined
          label="Zip code"
          prepend-icon="mdi-map-marker"
          hide-details="auto"
          @keypress.native="isNumber"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
      >
        <v-textarea
          v-model="business.notes"
          outlined
          label="Notes"
          prepend-icon="mdi-pen"
          hide-details="auto"
        ></v-textarea>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="business.home_based"
          outlined
          label="Home based?"
          hide-details="auto"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="business.solo_business"
          outlined
          label="Solo business?"
          hide-details="auto"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="business.private_address"
          outlined
          label="Private address?"
          hide-details="auto"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-btn
      @click="addBusiness()"
      x-large
      color="primary"
      bottom
      right
      absolute
      :class="{ 'v-btn--mobile absolute' : $vuetify.breakpoint.xsOnly }"
    >
      Add Business
    </v-btn>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex';
  import states from '@/mixin/states.js'

  export default {
    name: 'AddBusiness',
    mixins: [states],
    data() {
      return {
        business: {
          name: '',
          owner: '',
          co_owner: '',
          industry: '',
          type: '',
          email: '',
          owner_contact_number: '',
          co_owner_contact_number: '',
          streetaddress: '',
          state: '',
          city: '',
          county: '',
          country: '',
          zip_code: '',
          home_based: false,
          solo_business: false,
          private_address: false,
          notes: '',
          assigned_to: null
        },
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        digitRules: [
          v => !!v || 'Field is required',
          v => Number.isInteger(Number(v)) || 'Digits only.'
        ],
        overlay: false,
        showError: false,
        alert: false,
        selectedIndustry: null,
        selectedBusinessType: null,
        industryItems: [],
        businessTypes: [],
        staffSelected: null,
        staffEntries:[],
        isStaffLoading: false,
        staffSearch: null,
        defStaff:[{
          id: null,
          first_name: 'Unassigned',
          last_name: ''
        }],
      }
    },
    computed: {
      staffItems() {
        return this.staffEntries.map(entry => {
          const name = `${entry.first_name} ${entry.last_name}`
          return Object.assign({}, entry, { name })
        })
      }
    },
    created() {
      this.loadIndustries()
      this.getAllStaff({ limit: 0, offset: 0 })
      .then((response) => {
        this.staffEntries = [...this.defStaff, ...response.data.results]
      }, (error) => {
        console.log('error', error)
      });
    },
    methods: {
      ...mapActions(['createBusiness', 'getIndustries', 'getBusinessTypes', 'searchStaff', 'getAllStaff']),
      addBusiness() {
        // add date_created
        this.business.created_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()
        this.overlay = true;
        this.createBusiness(this.business)
        .then(() => {
          this.overlay = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.alert = true;
          setTimeout(() => {
            this.$router.push({ name: 'businessdirectorylist' })
          }, 3000)
        }, (error) => {
          console.log('error_message', error)
          this.showError = true
          this.overlay = false;
        });

      },
      isNumber(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if(charCode != 46 && charCode != 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.preventDefault();
        }
      },
      loadIndustries() {
        this.getIndustries()
        .then((response) => {
          this.industryItems = response.data.results
        }, (error) => {
          console.log('error_message', error)
        });
      },
      selectStaff(val) {
        this.business.assigned_to = val.id
      },
    },
    watch: {
      selectedIndustry(val) {
        this.business.industry = val
        this.business.type = null
        this.getBusinessTypes({ industry: val })
        .then((response) => {
          this.businessTypes = response.data.results
        }, (error) => {
          console.log('error_message', error)
        });
      },
      staffSearch (newVal) {
        this.isStaffLoading = true
        this.searchStaff({name:newVal, limit: 0, offset:0})
        .then(response => {
          this.staffEntries = [...this.defStaff, ...response.data.results]
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isStaffLoading = false))
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
